:root {
  --max-width: 83.75rem;
  --grid-gutter: 4.5rem;
  --side-padding: 6.25vw;
  --notification-bar-height: 3.375rem;
  --overlay-content-padding: 2rem;
  --mobile-menu-height: 4rem;
  --mobile-side-padding: 2.5rem;
  --overlay-children-bottom-padding: 8vh;
  --modal-content-side-padding: 1.5rem;
  --modal-content-vertical-padding: 2.5rem;
  --modal-footer-top-padding: 2rem;

  /* Color system */

  --white: #fff;
  --primary: #7FB72C;
  --blue: #0070b8;
  --grey: #404040;
  --grey-2: #F4F4F4;
  --grey-3: #707070;

  --secondary: #f5333f;
  --light-blue: #7896ab;
  --blue-grey: #7097ad;
  --very-light-pink: #f3f3f3;
  --blue-darker: #202944;

  
  /* Spacing */

  --headings-margin-bottom: 1rem;
  --paragraph-margin-bottom: 1rem;

  /* Body */

  --body-bg: var(--white);
  --body-color: var(--grey);


  /* Components */

  --border-radius: 0;

  /* Font, line-height */

  --font-family-1: Gilroy, sans-serif;
  --font-family-2: Gilroy, sans-serif;

  --base-font-size: 1rem;
  --base-line-height: 1.6;

  /* @media (width > 23.375em) { --overlay-content-padding: 3rem; }

  @media (--viewport-small) {
    --overlay-content-padding: 0;
    --base-font-size: calc(1rem + (20 - 16) * ((100vw - 48rem) / (1920 - 768)));
    --modal-content-side-padding: calc(3.25rem + (104 - 52) * ((100vw - 48rem) / (1920 - 768)));
    --modal-content-vertical-padding: calc(2.5rem + (60 - 40) * ((100vw - 48rem) / (1920 - 768)));
    --modal-footer-top-padding: calc(2rem + (48 - 32) * ((100vw - 48rem) / (1920 - 768)));
  }

  @media (--viewport-xlarge) {
    --base-font-size: 1.25rem;
    --modal-content-side-padding: 6.5rem;
    --modal-content-vertical-padding: 3.75rem;
    --modal-footer-top-padding: 3rem;
  } */
}
/* Normalize is licensed MIT. https://github.com/necolas/normalize.css */
/* Document

1. Change from `box-sizing: content-box` so that `width` is not affected by `padding` or `border`.
2. Change the default font family in all browsers.
3. Correct the line height in all browsers.
4. Prevent adjustments of font size after orientation changes in IE on Windows Phone and in iOS.
5. Change the default tap highlight to be completely transparent in iOS.

*/
*,
*::before,
*::after {
  -webkit-box-sizing: border-box;
          box-sizing: border-box; /* 1 */
}
html {
  min-height: 100vh;
  line-height: 1.15; /* 3 */
  -webkit-text-size-adjust: 100%; /* 4 */
  -webkit-tap-highlight-color: rgba(0,0,0, 0); /* 5 */
}
body {
  min-height: 100vh; /* 1, 3 */
  margin: 0;
  font-family: Gilroy, sans-serif;
  font-family: var(--font-family-1);
  font-size: 1rem;
  font-size: var(--base-font-size);
  line-height: 1.6;
  line-height: var(--base-line-height);
  background-color: #fff;
  background-color: var(--body-bg);
  color: #404040;
  color: var(--body-color);
}
body > #vue {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    min-height: 100vh;
  }
/* Shim for "new" HTML5 structural elements to display correctly (IE10, older browsers) */
 aside, figcaption, figure, footer, header, main, nav, section { display: block; }
/*
Suppress the focus outline on elements that cannot be accessed via keyboard.
This prevents an unwanted focus outline from appearing around elements that
might still respond to pointer events.
*/
[tabindex="-1"]:focus {
  outline: 0 !important;
}
/* Content grouping

1. Add the correct box sizing in Firefox.
2. Show the overflow in Edge and IE.

*/
input:focus::-webkit-input-placeholder {
  opacity: 0 !important;
}
input:focus::-moz-placeholder {
  opacity: 0 !important;
}
input:focus:-ms-input-placeholder {
  opacity: 0 !important;
}
input:focus::-ms-input-placeholder {
  opacity: 0 !important;
}
input:focus::placeholder {
  opacity: 0 !important;
}
/* Typography

Remove top margins from headings

By default, `<h1>`-`<h6>` all receive top and bottom margins. We nuke the top
margin for easier control within type scales as it avoids margin collapsing. */
h1,
h2,
h3 {
  margin-top: 0;
}
/* Reset margins on paragraphs */
p {
  margin: 0;
}
/* Abbreviations

   1. Duplicate behavior to the data-* attribute for our tooltip plugin
   2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
   3. Add explicit cursor to indicate changed behavior.
   4. Remove the bottom border in Firefox 39-.
   5. Prevent the text-decoration to be skipped.*/
blockquote {
  margin: 0 0 1rem;
}

strong {
  font-weight: 700; /* Add the correct font weight in Chrome, Edge, and Safari */
}
/*
Prevent `sub` and `sup` elements from affecting the line height in
all browsers.
*/
/* Links */
a {
  color: inherit;
  cursor: pointer;
  text-decoration: inherit;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}
/* Code */
/* Figures */
figure {
  /* Apply a consistent margin strategy (matches our type styles). */
  margin: 0;
}
/* Images and content */
img {
  vertical-align: middle;
  max-width: 100%;
  border-style: none; /* Remove the border on images inside links in IE 10-. */
}
svg {
  /* Workaround for the SVG overflow bug in IE10/11 is still required.
     See https://github.com/twbs/bootstrap/issues/26878 */
  overflow: hidden;
  vertical-align: middle;
}
/* Tables */
table {
  border-collapse: collapse; /* Prevent double borders */
}
caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #f3f3f3;
  color: var(--very-light-pink);
  text-align: left;
  caption-side: bottom;
}

dl {
  margin: 0;
  font-size: var(--font-size-base);
  line-height: var(--line-height-base);
}
figcaption {
  margin-top: 1.375em;
  font-size: 0.875rem;
  font-style: italic;
}
.text-lg {
  font-size: 1.125rem;
}
/* .link-small {
  font-size: 0.875rem;
  font-style: italic;
  line-height: normal;
  text-align: right;
  color: var(--secondary);
  border-bottom: 0.0625rem solid transparent;
  transition: border-color .2s;

  @media (hover: hover), (-ms-high-contrast:none) {
    &:hover {
      border-color: currentColor;
    }
  }
} */
.rich-text > p {
    margin: 0.9em 0;
    line-height: 2;
  }
.rich-text > h2,
  .rich-text > h3 {
    margin: 1.5em 0 1em;
    font-family: Gilroy, sans-serif;
    font-family: var(--font-family-2);
    font-size: 2.625rem;
    font-weight: 700;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #7FB72C;
    color: var(--primary);
  }
.rich-text > h3 {
    font-size: 2rem;
  }
.rich-text > p:first-child, .rich-text > h1:first-child, .rich-text > h2:first-child, .rich-text > h3:first-child {
      margin-top: 0 !important;
    }
.rich-text > p:last-child, .rich-text > h1:last-child, .rich-text > h2:last-child, .rich-text > h3:last-child {
      margin-bottom: 0 !important;
    }
.rich-text a {
    color: #7FB72C;
    color: var(--primary)
  }
@media (hover: hover), (-ms-high-contrast: none) {
      .rich-text a:hover {
        text-decoration: underline;
      }
    }
.rich-text ul {
    margin: 0.9em 0;
    padding-left: 0;
    list-style: none;
  }
.rich-text ul > li {
      position: relative;
      padding-left: 20px
    }
.rich-text ul > li:before {
        content: "";
        width: 8px;
        height: 8px;
        background: #7FB72C;
        background: var(--primary);
        position: absolute;
        top: 8px;
        left: 0;
      }
.rich-text ul > li + li {
      margin-top: 0.9em;
    }
.rich-text table {
    margin: 5em 0;
    width: 100%;
    border-left: 2px solid #F4F4F4;
    border-left: 2px solid var(--grey-2);
    border-bottom: 2px solid #F4F4F4;
    border-bottom: 2px solid var(--grey-2);
  }
.rich-text table td {
      border-top: 2px solid #F4F4F4;
      border-top: 2px solid var(--grey-2);
      border-right: 2px solid #F4F4F4;
      border-right: 2px solid var(--grey-2);
      padding: 16px 20px;
    }
/*
And undo these styles for placeholder links/named anchors (without href)
which have not been made explicitly keyboard-focusable (without tabindex).
It would be more straightforward to just use a[href] in previous block, but that
causes specificity issues in many other styles that are too complex to fix.
See https://github.com/twbs/bootstrap/issues/19402
*/
a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;

  /*  @include hover-focus {
    color: inherit;
    text-decoration: none;
  }*/
}
a:not([href]):not([tabindex]):focus {
  outline: 0;
}
/* @media (--viewport-small) {
  figcaption {font-size: calc(0.875rem + (16 - 14) * ((100vw - 48rem) / (1920 - 768)));}
  .rich-text {
    h2 {font-size: calc(1.5rem + (30 - 24) * ((100vw - 48rem) / (1920 - 768)));}
    h3 {font-size: calc(1.25rem + (24 - 20) * ((100vw - 48rem) / (1920 - 768)));}
  }
  .link-small { font-size: calc(0.875rem + (18 - 14) * ((100vw - 48rem) / (1920 - 768))); }
  .text-lg { font-size: calc(1.125rem + (22 - 18) * ((100vw - 48rem) / (1920 - 768))); }
}

@media (--viewport-large) {
  figcaption {font-size: 1rem;}
  .rich-text {
    h2 {font-size: 1.875rem;}
    h3 {font-size: 1.5rem;}
  }
  .text-lg { font-size: 1.375rem; }
  .link-small { font-size: 1.125rem; }
} */
.section-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  margin: 0 0 0.875em;
  font-family: Gilroy, sans-serif;
  font-family: var(--font-family-1);
  font-size: 2rem;
  font-weight: 400;
  line-height: 1.27;
  color: #7FB72C;
  color: var(--primary)
}
.section-title:after {
    content: '';
    display: block;
    height: 0.0625rem;
    width: 100%;
    max-width: 18.75rem;
    margin-top: 0.75em;
    background: #f5333f;
    background: var(--secondary);
  }
img + .section-title,
  .three-stars + .section-title {
    margin-top: 0.7083em
  }
.section-title.-lg { font-size: 2.25rem; }
.section-title.-sm { font-size: 1.625rem; }
.sans-serif-title {
  margin: 0;
  font-family: Gilroy, sans-serif;
  font-family: var(--font-family-2);
  font-size: 1.25rem;
  font-weight: normal;
  line-height: normal;
  letter-spacing: 0.0833em;
  text-transform: uppercase;
  color: #7FB72C;
  color: var(--primary);
}
@media (min-width: 47.9385em) {
  .section-title {
    font-size: calc(2rem + (48 - 32) * ((100vw - 48rem) / (1920 - 768)))
  }

    .section-title.-lg { font-size: calc(2.25rem + (54 - 36) * ((100vw - 48rem) / (1920 - 768))); }
    .section-title.-sm { font-size: calc(1.625rem + (40 - 26) * ((100vw - 48rem) / (1920 - 768))); }
  .sans-serif-title { font-size: calc(1.25rem + (24 - 20) * ((100vw - 48rem) / (1920 - 768))); }

}
@media (min-width: 120.001em) {
  .section-title {
    font-size: 3rem
  }

    .section-title.-lg { font-size: 3.375rem; }
    .section-title.-sm { font-size: 2.5rem; }

  .sans-serif-title {font-size: 1.5rem;}
}
/* Correct element displays */
summary {
  display: list-item; /* Add the correct display in all browsers */
  cursor: pointer;
}
template {
  display: none; /* Add the correct display in IE */
}
/* Always hide an element with the `hidden` HTML attribute (from PureCSS).
   Needed for proper display in IE 10-. */
[hidden] {
  display: none !important;
}
/*
* Remove the gap between audio, canvas, iframes,
* images, videos and the bottom of their containers:
* https://github.com/h5bp/html5-boilerplate/issues/440
*/

canvas,
iframe,
svg {
  vertical-align: middle;
}
svg:not(:root) {
  overflow: hidden;
}
/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on September 16, 2019 */
@font-face {
    font-family: Gilroy;
    src: url(/dist/fonts/gilroy-blackitalic-webfont.woff2) format('woff2'),
         url(/dist/fonts/gilroy-blackitalic-webfont.woff) format('woff');
    font-weight: 900;
    font-style: italic;

}
@font-face {
    font-family: Gilroy;
    src: url(/dist/fonts/gilroy-black-webfont.woff2) format('woff2'),
         url(/dist/fonts/gilroy-black-webfont.woff) format('woff');
    font-weight: 900;
    font-style: normal;

}
@font-face {
    font-family: Gilroy;
    src: url(/dist/fonts/gilroy-bold-webfont.woff2) format('woff2'),
         url(/dist/fonts/gilroy-bold-webfont.woff) format('woff');
    font-weight: 700;
    font-style: normal;

}
@font-face {
    font-family: Gilroy;
    src: url(/dist/fonts/gilroy-bolditalic-webfont.woff2) format('woff2'),
         url(/dist/fonts/gilroy-bolditalic-webfont.woff) format('woff');
    font-weight: 700;
    font-style: italic;

}
@font-face {
    font-family: Gilroy;
    src: url(/dist/fonts/gilroy-extrabold-webfont.woff2) format('woff2'),
         url(/dist/fonts/gilroy-extrabold-webfont.woff) format('woff');
    font-weight: 800;
    font-style: normal;

}
@font-face {
    font-family: Gilroy;
    src: url(/dist/fonts/gilroy-extrabolditalic-webfont.woff2) format('woff2'),
         url(/dist/fonts/gilroy-extrabolditalic-webfont.woff) format('woff');
    font-weight: 800;
    font-style: italic;

}
@font-face {
    font-family: Gilroy;
    src: url(/dist/fonts/gilroy-light-webfont.woff2) format('woff2'),
         url(/dist/fonts/gilroy-light-webfont.woff) format('woff');
    font-weight: 200;
    font-style: normal;

}
@font-face {
    font-family: Gilroy;
    src: url(/dist/fonts/gilroy-lightitalic-webfont.woff2) format('woff2'),
         url(/dist/fonts/gilroy-lightitalic-webfont.woff) format('woff');
    font-weight: 200;
    font-style: italic;

}
@font-face {
    font-family: Gilroy;
    src: url(/dist/fonts/gilroy-medium-webfont.woff2) format('woff2'),
         url(/dist/fonts/gilroy-medium-webfont.woff) format('woff');
    font-weight: 500;
    font-style: normal;

}
@font-face {
    font-family: Gilroy;
    src: url(/dist/fonts/gilroy-mediumitalic-webfont.woff2) format('woff2'),
         url(/dist/fonts/gilroy-mediumitalic-webfont.woff) format('woff');
    font-weight: 500;
    font-style: italic;

}
.l-landing,
.l-landing body {
    height: 100%;
    min-height: 100%;
    padding: 0;
    margin: 0;
}
.l-landing body {
    background: #fff url(/dist/img/landing.f02c15f2b10f43873d30955369f7b61f.jpg) center/cover;
}
.l-landing .l-page {
        display: table;
        width: 98%;
        height: 100%;
        margin: auto;
    }
.l-landing .inner {
        display: table-cell;
        vertical-align: middle;
        text-align: center;
    }
.l-landing .logo {
        width: 100%;
        max-width: 426px;
        margin: 0 auto 124px;
    }
.l-landing .logo svg {
            width: auto;
            height: 115px;
        }
.l-landing .links {
        margin: 0;
        padding: 0;
        list-style: none;
    }
.l-landing .links li + li {
            margin-top: 40px;
        }
.l-landing .links a {
        color: #404040;
        text-decoration: none;
        font-size: 18px;
        font-weight: 600;
    }
.l-landing .links a:hover {
        color: #0070B8;
    }
.l-page {
  min-height: 1px; /* ie11 */
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
  -webkit-transition: padding-top .5s ease-in-out;
  transition: padding-top .5s ease-in-out
}
.l-header {
  padding: 30px 0;
  background: #fff;
  background: var(--white);
  -webkit-box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.08);
          box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.08);
}
@media only screen and (max-width: 980px) {
.nav {
    display: none
}
  }
.nav-list {
  margin: 0;
  padding: 0;
  list-style: none;
}
.nav-list > li + li {
    margin-top: 1px;
  }
.nav-link {
  display: block;
  padding: 27px 30px;
  font-size: 1.125rem;
  line-height: 1.55;
  background: #F4F4F4;
  background: var(--grey-2)
}
@media (hover: hover), (-ms-high-contrast: none) {
    .nav-link:hover {
      color: #7FB72C;
      color: var(--primary);
    }
  }
.nav-link.-active {
    background: #7FB72C;
    background: var(--primary);
    color: #fff;
    color: var(--white);
    font-weight: 700;
  }
@media only screen and (min-width: 981px) {
.nav-mobile {
    display: none
}
  }
.nav-mobile-title {
  position: relative;
  background: #7FB72C;
  background: var(--primary);
  color: #fff;
  color: var(--white);
  font-weight: 700;
  padding: 29px 6.25vw;
  padding: 29px var(--side-padding);
  font-size: 1.125rem;
  line-height: 1.8;
  cursor: pointer;
}
.nav-mobile-title > svg {
    position: absolute;
    top: 50%;
    right: 6.25vw;
    right: var(--side-padding);
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
  }
.nav-mobile.is-active .nav-mobile-title > svg {
    -webkit-transform: translateY(-50%) rotate(-180deg);
            transform: translateY(-50%) rotate(-180deg);
  }
.nav-mobile-list {
  display: none;
  margin: 0;
  padding: 0;
  list-style: none;
}
.nav-mobile.is-active .nav-mobile-list {
    display: block;
  }
.nav-mobile-list li + li {
    margin-top: 1px;
  }
.nav-mobile-link {
  display: block;
  background: #F4F4F4;
  background: var(--grey-2);
  padding: 24px 6.25vw;
  padding: 24px var(--side-padding);
  font-size: 1.125rem;
  line-height: 1.8
}
@media (hover: hover), (-ms-high-contrast: none) {
    .nav-mobile-link:hover {
      color: #7FB72C;
      color: var(--primary);
    }
  }
.l-section {
  padding-top: 3.125rem;
  padding-bottom: 3.125rem
}
.l-section.-lg {
    padding-top: 6.25rem;
    padding-bottom: 6.25rem;
  }
.l-section.-xl {
    padding-top: 9.25rem;
    padding-bottom: 9.25rem;
  }
.l-section {
}
html.rtl .l-section {
    direction: rtl;
  }
@media only screen and (max-width: 767px) {
    .l-section.-xl {
      padding-top: 4.5rem;
      padding-bottom: 4.5rem;
    }
  }
/* @media (--viewport-small) {
  .l-section {
    padding: calc(3.125rem + (80 - 50) * ((100vw - 48rem) / (1920 - 768))) 0;
    &.-lg { padding: calc(4.0625rem + (120 - 65) * ((100vw - 48rem) / (1920 - 768))) 0; }
    &.-xl { padding: calc(6.25rem + (150 - 100) * ((100vw - 48rem) / (1920 - 768))) 0; }
  }
} */
/* @media (--viewport-xlarge) {
  .l-section {
    padding-top: 5rem;
    padding-bottom: 5rem;

    &.-lg {
      padding-top: 7.5rem;
      padding-bottom: 7.5rem;
    }

    &.-xl {
      padding-top: 10rem;
      padding-bottom: 10rem;
    }
  }
} */
.section-header {
  margin-bottom: 3.75rem;
}
.section-header > .right { margin-top: 0.625rem; }
.section-header.-with-border {
    border-top: solid 0.25rem #7FB72C;
    border-top: solid 0.25rem var(--primary);
    padding-top: 1.5rem;
  }
@media (min-width: 25.876em) {
.section-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: baseline;
        -ms-flex-align: baseline;
            align-items: baseline;
}

    .section-header > .right {
      margin-left: 2rem;
      -webkit-box-flex: 0;
          -ms-flex: 0 0 auto;
              flex: 0 0 auto;
    }
  }
@media (min-width: 47.9385em) {
.section-header { margin-bottom: calc(3.75rem + (90 - 60) * ((100vw - 48rem) / (1920 - 768)))
} }
@media (min-width: 120.001em) {
.section-header { margin-bottom: 5.625rem
} }
.l-buttons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.l-buttons > .button + .button { margin-top: 2.625rem; }
@media (min-width: 47.9385em) {
.l-buttons {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    margin-left: -2.625rem;
}

    .l-buttons > .button {
      margin-top: 0 !important;
      margin-left: 2.625rem;
    }
  }
@media (min-width: 120.001em) {
    .l-buttons > .button { margin-left: 3.875rem; }
  }
.l-footer {
  padding: 50px 0;
  background-color: #7FB72C;
  background-color: var(--primary);
  text-align: center;
  color: #fff;
  color: var(--white);
}
.l-footer .links {
    list-style: none;
    padding: 0;
    margin: 40px 0 80px;
  }
.l-footer .links li {
      display: inline;
      padding: 0 25px;
    }
@media (hover: hover), (-ms-high-contrast: none) {
        .l-footer .links a:hover {
          opacity: 0.6;
          text-decoration: underline;
        }
      }
.l-footer .link-email {
    font-size: 1.125rem;
    margin-bottom: 1.25rem
  }
@media (hover: hover), (-ms-high-contrast: none) {
      .l-footer .link-email:hover {
        text-decoration: underline;
      }
    }
.l-footer .copy {
    font-size: 0.875rem;
    opacity: 0.4;
  }
html.rtl .l-footer {
    direction: rtl;
  }
.l-resources .intro {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }
.l-resources .img {
    width: 31.9%;
  }
.l-resources .img img {
      width: 100%;
      /* box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.3); */
      /* filter: drop-shadow(0 0 40px rgb(0, 0, 0, 0.3)); */
    }
.l-resources .img a {
      display: block;
      text-align: center;
      margin-top: 15px;
      font-weight: 700;
      line-height: 1.5;
      color: #0070b8;
      color: var(--blue)
    }
@media (hover: hover), (-ms-high-contrast: none) {
        .l-resources .img a:hover {
          text-decoration: underline;
        }
      }
.l-resources .txt {
    width: 64.1%;
  }
@media only screen and (max-width: 640px) {
    .l-resources .intro {
      display: block;
    }

    .l-resources .img,
    .l-resources .txt {
      width: 100%;
    }

    .l-resources .img + .txt {
      margin-top: 40px;
    }
  }
/* @import "overlay/index.pcss";
@import "modal/index.pcss"; */
.l-container {
  margin: 0 auto;
  width: 100%;
  max-width: calc(83.75rem + (6.25vw * 2));
  max-width: calc(var(--max-width) + (var(--side-padding) * 2));
  padding-left: 6.25vw;
  padding-left: var(--side-padding);
  padding-right: 6.25vw;
  padding-right: var(--side-padding)
}
.l-container.-w932 {
    max-width: calc(58.25rem + (6.25vw * 2));
    max-width: calc(58.25rem + (var(--side-padding) * 2));
  }
.l-container.-w1040 {
    max-width: calc(65rem + (6.25vw * 2));
    max-width: calc(65rem + (var(--side-padding) * 2));
  }
.l-container.-space-top {
    padding-top: 6.2rem;
  }
.l-content-grid {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.l-content {
  width: 70.922%;
}
.l-side {
  width: 21.064%;
}
.l-mb-30 {
  margin-bottom: 30px;
}
@media only screen and (max-width: 980px) {
  .l-content-grid {
    display: block;
  }

  .l-content,
  .l-side {
    width: 100%;
  }
}
.branding {
  display: block;
  width: 100%;
  font-size: 0;
}
.branding > .icon {
    display: block;
    width: 165px;
    height: 67px;
  }
.button {
  display: inline-block;
  padding: 0.25em 1em;
  min-width: 8rem;
  border: 0.125rem solid #f3f3f3;
  border: 0.125rem solid var(--very-light-pink);
  font-weight: 400;
  font-size: 1rem;
  font-family: Gilroy, sans-serif;
  font-family: var(--font-family-2);
  line-height: 1.5;
  letter-spacing: 0.125em;
  color: #404040;
  color: var(--body-color);
  text-align: center;
  /* text-transform: uppercase; */
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  background-color: #f3f3f3;
  background-color: var(--very-light-pink);
  cursor: pointer;
  -webkit-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;
  border-radius: 2px

}
.button.-primary {
    background-color: #7FB72C;
    background-color: var(--primary);
    border-color: #7FB72C;
    border-color: var(--primary);
    color: #fff;
    color: var(--white);
  }
.button {

  /* &.-secondary {
    background-color: var(--secondary);
    border-color: var(--secondary);
    color: #f7f7f7;
  } */

  /* &.-light-blue {
    background-color: var(--light-blue);
    border-color: var(--light-blue);
    color: #f7f7f7;

    @media (hover: hover), (-ms-high-contrast:none) {
      &:hover {
        background-color: var(--primary);
        border-color: var(--primary);
      }
    }
  } */



  /* &.-outline {
    background: transparent;
    border-color: var(--inherit);
    color: var(--primary);

    &.-white {
      color: var(--white);

      @media (hover: hover), (-ms-high-contrast:none) {
        &:hover {
          color: var(--primary);
          border-color: var(--white);
          background: var(--white);
        }
      }
    }


  } */

}
.icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  fill: currentColor;
}
.-go-back .icon {
    font-size: 9px;
    margin-right: 5px;
    position: relative;
    top: -1px;
  }
.fluid-icon {
  position: relative;
}
.fluid-icon svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
.nav-bar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  color: #7FB72C;
  color: var(--primary);
}
.nav-bar > .branding {
    width: 165px;
    margin-right: auto;
  }
.nav-bar-content-link {
  color: #404040;
  color: var(--grey);
}
@media only screen and (max-width: 767px) {
.nav-bar-content {
    display: none
}
  }
.hero {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  min-height: 17.7083vw;
  /* background-color: var(--very-light-pink); */
}
/* > .header {
    display: none;
    flex: 0 0 auto;
    padding: 2.8125rem 0;
    width: 100%;
  } */
.hero .content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
            flex: 1 1 auto;
    padding-top: 150px;
    padding-bottom: 150px;
  }
.hero {

  /* &.-contact { min-height: 35.4167vw; }
  &.-support { min-height: 32.5521vw; } */

  /* &.-center {
    > .content > .l-container {
      flex: 0 0 auto;
      text-align: center;
      max-width: calc(35rem + var(--side-padding) * 2);
    }
  } */
}
.form-outer {
  padding: 31px;
  background: #F4F4F4;
  background: var(--grey-2);
  margin: 50px 0;
}
.block .form-outer {
    margin: 0;
  }
.form-outer .title {
    color: #7FB72C;
    color: var(--primary);
    font-size: 1.5rem;
    margin-bottom: 5px;
  }
.form-outer--ltr {
  direction: ltr;
}
.form-outer--rtl {
  direction: rtl;
}
.form-inner {
  position: relative;
  padding-right: 157px;
  margin: 30px 0 15px;
}
.form-inner .button.-submit {
    width: 147px;
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 4px;
    line-height: 1.2;
    padding: 16px 1em;
    -webkit-appearance: none;
  }
.form-input {
  width: 100%;
  /* height: 55px; */
  border: 0;
  border-radius: 4px;
  background-color: #fff;
  background-color: var(--white);
  padding: 18px 20px;
  color: #707070;
  color: var(--grey-3);
  font-size: var(--font-size-base);
  line-height: 1.2
}
.form-input::-webkit-input-placeholder {
    color: #707070;
    color: var(--grey-3);
  }
.form-input::-moz-placeholder {
    color: #707070;
    color: var(--grey-3);
  }
.form-input:-ms-input-placeholder {
    color: #707070;
    color: var(--grey-3);
  }
.form-input::-ms-input-placeholder {
    color: #707070;
    color: var(--grey-3);
  }
.form-input::placeholder {
    color: #707070;
    color: var(--grey-3);
  }
.form-label-checkbox {
  position: relative;
  font-size: var(--font-size-base);
  cursor: pointer;
}
.form-label-checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
.form-outer--ltr .form-label-checkbox {
    padding-left: 20px;
  }
.form-outer--rtl .form-label-checkbox {
    padding-left: 0;
    padding-right: 20px;
  }
.form-checkbox {
  width: 12px;
  height: 12px;
  border: solid 2px #7FB72C;
  border: solid 2px var(--primary);
  border-radius: 1px;
  position: absolute;
  top: 3px;
}
.form-outer--ltr .form-checkbox {
    left: 0;
  }
.form-outer--rtl .form-checkbox {
    right: 0;
  }
.form-checkbox svg {
    opacity: 0;
    -webkit-transition: opacity 0.2s;
    transition: opacity 0.2s;
    font-size: 6px;
    fill: #7FB72C;
    fill: var(--primary);
    position: absolute;
    top: 1px;
    left: 1px;
  }
input:checked ~ .form-checkbox svg {
  opacity: 1;
}
.parsley-errors-list {
  color: red;
}
.language-dropdown {
  position: relative;
  display: inline-block;
  z-index: 10;
}
.language-dropdown > .links {
    display: none;
    position: absolute;
    left: 0;
    right: 0;
    top: 45px;
    border-radius: 2px;
    background: #7FB72C;
    background: var(--primary);
    margin: 0;
    padding: 0;
    list-style: none;
  }
.language-dropdown > .links a {
      position: relative;
      display: block;
      color: #fff;
      color: var(--white);
      padding: 9px 50px;
      font-size: 1.125rem;
      line-height: 1.25;
      border-radius: 2px
    }
.language-dropdown > .links a.is-active {
        color: rgba(255, 255, 255, 0.46)
      }
.language-dropdown > .links a.is-active:before {
          content: "";
          position: absolute;
          top: 50%;
          left: 19px;
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background: #fff;
          background: var(--white);
          -webkit-transform: translateY(-50%);
                  transform: translateY(-50%);
        }
@media (hover: hover), (-ms-high-contrast: none) {
        .language-dropdown > .links a:hover {
          background: rgba(255, 255, 255, 0.2);
        }
      }
.language-dropdown.is-active > .links {
      display: block;
    }
.nav-bar-content-link + .language-dropdown {
  margin-left: 55px;
}
.language-dropdown-btn {
  position: relative;
  padding: 9px 50px;
  font-size: 1.125rem;
  line-height: 1.25;
  font-family: Gilroy, sans-serif;
  font-family: var(--font-family-1);
  color: #fff;
  color: var(--white);
  background: #7FB72C;
  background: var(--primary);
  border: 0;
  border-radius: 2px;
  cursor: pointer;
  min-width: 217px;
  text-align: left;
}
.language-dropdown-btn .icon-1,
  .language-dropdown-btn .icon-2 {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
  }
.language-dropdown-btn .icon-1 {
    left: 15px;
  }
.language-dropdown-btn .icon-2 {
    right: 20px;
  }
.language-dropdown.is-active .language-dropdown-btn .icon-2 {
    -webkit-transform: translateY(-50%) rotate(-180deg);
            transform: translateY(-50%) rotate(-180deg);
  }
.flexible-content > .block {
    margin: 2rem 0
  }
.flexible-content > .block:first-child {
      margin-top: 0 !important;
    }
.flexible-content > .block:last-child {
      margin-bottom: 0 !important;
    }
@media (min-width: 47.9385em) {
    .flexible-content > .block {
      margin: calc(2rem + (48 - 32) * ((100vw - 48rem) / (1920 - 768))) 0;
    }
  }
@media (min-width: 120.001em) {
    .flexible-content > .block {
      margin: 3rem 0;
    }
  }
.flexible-content img {
    border-radius: 2px;
  }
.callout {
  padding-left: 56px;
  border-left: 4px solid #7FB72C;
  border-left: 4px solid var(--primary);
}
.callout-quote {
  font-size: 1.5rem;
  line-height: 1.75;
  font-style: italic;
  font-weight: 500;
}
.callout-source {
  margin-top: 50px;
  font-size: 0.75rem;
  line-height: 2;
  font-style: italic;
  font-weight: 200;
  text-transform: uppercase;
}
.callout-source a,
  .callout-source span {
    font-size: 1rem;
    line-height: 1.5;
    font-style: normal;
    font-weight: 700;
    text-transform: none;
    color: #7FB72C;
    color: var(--primary);
  }
.call-to-action {
  padding: 100px 90px;
  background: #F4F4F4;
  background: var(--grey-2);
}
.call-to-action p {
    font-size: 1.125rem;
    line-height: 2;
  }
.call-to-action .rich-text + .button {
    margin-top: 40px;
  }
.call-to-action .button {
    padding: 13px 40px;
  }
.call-to-action .button > svg {
      margin-left: 10px;
    }
.rtl .call-to-action .button > svg {
    margin-left: 0;
    margin-right: 10px;
  }
.slider {
  position: relative;
  direction: ltr !important;
}
.slick-next,
.slick-prev {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 0;
  cursor: pointer;
  background: #F4F4F4;
  background: var(--grey-2);
}
.slick-next svg, .slick-prev svg {
    fill: #404040;
    fill: var(--grey);
  }
@media (hover: hover), (-ms-high-contrast: none) {
    .slick-next:hover, .slick-prev:hover {
      background: #7FB72C;
      background: var(--primary);
    }

      .slick-next:hover svg, .slick-prev:hover svg {
        fill: #fff;
        fill: var(--white);
      }
  }
.slick-prev {
  right: 50px;
}
.slick-list {
  line-height: 1;
}
.slick-dots {
  margin: 0;
  padding: 30px 0 8px;
  list-style: none;
  line-height: 1;
}
.slick-dots li {
    height: 10px;
    display: inline-block;
  }
.slick-dots li + li {
    margin-left: 10px;
  }
.slick-dots button {
    width: 10px;
    height: 10px;
    border: 1px solid #7FB72C;
    border: 1px solid var(--primary);
    border-radius: 50%;
    text-indent: -9999px;
    overflow: hidden;
    cursor: pointer;
    padding: 0;
    background: transparent;
  }
.slick-dots .slick-active button {
    background: #7FB72C;
    background: var(--primary);
  }
.play-button-outer {
  position: relative;
}
.play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.5);
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.play-button svg {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    margin-left: 1px;
  }
/* @font-face {
    font-family: 'lg';
    src: url("../fonts/lg.eot?n1z373");
    src: url("../fonts/lg.eot?#iefixn1z373") format("embedded-opentype"), url("../fonts/lg.woff?n1z373") format("woff"), url("../fonts/lg.ttf?n1z373") format("truetype"), url("../fonts/lg.svg?n1z373#lg") format("svg");
    font-weight: normal;
    font-style: normal;
  } */
/* stylelint-disable */
.lg-icon {
  /* font-family: 'lg'; */
  speak: none;
  font-style: normal;
  font-weight: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.lg-actions .lg-next,
.lg-actions .lg-prev {
  /* background-color: rgba(0, 0, 0, 0.45); */
  border-radius: 2px;
  color: #999;
  cursor: pointer;
  display: block;
  font-size: 22px;
  margin-top: -10px;
  padding: 8px 10px 9px;
  position: absolute;
  top: 50%;
  z-index: 1080;
}
.lg-actions .lg-next svg,
.lg-actions .lg-prev svg {
  fill: #fff;
  width: 30px;
  height: 14px;
}
.lg-actions .lg-next.disabled,
.lg-actions .lg-prev.disabled {
  pointer-events: none;
  opacity: 0.5;
}
.lg-actions .lg-next:hover,
.lg-actions .lg-prev:hover {
  color: #fff;
}
.lg-actions .lg-next {
  right: 20px;
}
/* .lg-actions .lg-next:before {
    content: "\e095";
  } */
.lg-actions .lg-prev {
  left: 20px;
}
/* .lg-actions .lg-prev:after {
    content: "\e094";
  } */
@-webkit-keyframes lg-right-end {
  0% {
    left: 0;
  }
  50% {
    left: -30px;
  }
  100% {
    left: 0;
  }
}
@keyframes lg-right-end {
  0% {
    left: 0;
  }
  50% {
    left: -30px;
  }
  100% {
    left: 0;
  }
}
@-webkit-keyframes lg-left-end {
  0% {
    left: 0;
  }
  50% {
    left: 30px;
  }
  100% {
    left: 0;
  }
}
@keyframes lg-left-end {
  0% {
    left: 0;
  }
  50% {
    left: 30px;
  }
  100% {
    left: 0;
  }
}
.lg-outer.lg-right-end .lg-object {
  -webkit-animation: lg-right-end 0.3s;
  animation: lg-right-end 0.3s;
  position: relative;
}
.lg-outer.lg-left-end .lg-object {
  -webkit-animation: lg-left-end 0.3s;
  animation: lg-left-end 0.3s;
  position: relative;
}
.lg-toolbar {
  z-index: 1082;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  /* background-color: rgba(0, 0, 0, 0.45); */
}
.lg-toolbar .lg-icon {
  color: #fff;
  cursor: pointer;
  /* float: right; */
  font-size: 24px;
  height: 47px;
  line-height: 27px;
  padding: 10px 0;
  text-align: center;
  width: 50px;
  text-decoration: none !important;
  outline: medium none;
  -webkit-transition: color 0.2s linear;
  transition: color 0.2s linear;
}
.lg-toolbar .lg-icon:hover {
  color: #fff;
}
.lg-toolbar .lg-close {
  position: relative;
  width: 28px;
  height: 28px;
  display: block;
  float: right;
  margin: 10px;
  background: url(/dist/img/close.658fe6fab5e200f30a75b17ae2c20439.svg) no-repeat;
}
.lg-toolbar .lg-close:after {
  content: "esc";
  position: absolute;
  top: 33px;
  left: 0;
  right: 0;
  font-size: 12px;
  font-family: Arial, Helvetica, sans-serif;
  text-align: center;
  line-height: 1;
}
.lg-toolbar .lg-download:after {
  content: "\e0f2";
}
.lg-sub-html {
  background-color: rgba(0, 0, 0, 0.45);
  bottom: 0;
  color: #eee;
  font-size: 16px;
  left: 0;
  padding: 10px 40px;
  position: fixed;
  right: 0;
  text-align: center;
  z-index: 1080;
}
.lg-sub-html h4 {
  margin: 0;
  font-size: 13px;
  font-weight: bold;
}
.lg-sub-html p {
  font-size: 12px;
  margin: 5px 0 0;
}
#lg-counter {
  color: #999;
  display: inline-block;
  font-size: 16px;
  padding-left: 20px;
  padding-top: 12px;
  vertical-align: middle;
}
.lg-toolbar,
.lg-prev,
.lg-next {
  opacity: 1;
  -webkit-transition: -webkit-transform 0.35s cubic-bezier(0, 0, 0.25, 1) 0s,
    opacity 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, color 0.2s linear;
  -webkit-transition: opacity 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, color 0.2s linear,
    -webkit-transform 0.35s cubic-bezier(0, 0, 0.25, 1) 0s;
  transition: opacity 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, color 0.2s linear,
    -webkit-transform 0.35s cubic-bezier(0, 0, 0.25, 1) 0s;
  transition: transform 0.35s cubic-bezier(0, 0, 0.25, 1) 0s,
    opacity 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, color 0.2s linear;
  transition: transform 0.35s cubic-bezier(0, 0, 0.25, 1) 0s,
    opacity 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, color 0.2s linear,
    -webkit-transform 0.35s cubic-bezier(0, 0, 0.25, 1) 0s;
}
.lg-hide-items .lg-prev {
  opacity: 0;
  -webkit-transform: translate3d(-10px, 0, 0);
  transform: translate3d(-10px, 0, 0);
}
.lg-hide-items .lg-next {
  opacity: 0;
  -webkit-transform: translate3d(10px, 0, 0);
  transform: translate3d(10px, 0, 0);
}
.lg-hide-items .lg-toolbar {
  opacity: 0;
  -webkit-transform: translate3d(0, -10px, 0);
  transform: translate3d(0, -10px, 0);
}
body:not(.lg-from-hash) .lg-outer.lg-start-zoom .lg-object {
  -webkit-transform: scale3d(0.5, 0.5, 0.5);
  transform: scale3d(0.5, 0.5, 0.5);
  opacity: 0;
  -webkit-transition: -webkit-transform 250ms cubic-bezier(0, 0, 0.25, 1) 0s,
    opacity 250ms cubic-bezier(0, 0, 0.25, 1) !important;
  -webkit-transition: opacity 250ms cubic-bezier(0, 0, 0.25, 1),
    -webkit-transform 250ms cubic-bezier(0, 0, 0.25, 1) 0s !important;
  transition: opacity 250ms cubic-bezier(0, 0, 0.25, 1),
    -webkit-transform 250ms cubic-bezier(0, 0, 0.25, 1) 0s !important;
  transition: transform 250ms cubic-bezier(0, 0, 0.25, 1) 0s,
    opacity 250ms cubic-bezier(0, 0, 0.25, 1) !important;
  transition: transform 250ms cubic-bezier(0, 0, 0.25, 1) 0s,
    opacity 250ms cubic-bezier(0, 0, 0.25, 1),
    -webkit-transform 250ms cubic-bezier(0, 0, 0.25, 1) 0s !important;
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
}
body:not(.lg-from-hash)
  .lg-outer.lg-start-zoom
  .lg-item.lg-complete
  .lg-object {
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  opacity: 1;
}
.lg-outer .lg-thumb-outer {
  background-color: #0d0a0a;
  bottom: 0;
  position: absolute;
  width: 100%;
  z-index: 1080;
  max-height: 350px;
  -webkit-transform: translate3d(0, 100%, 0);
  transform: translate3d(0, 100%, 0);
  -webkit-transition: -webkit-transform 0.25s cubic-bezier(0, 0, 0.25, 1) 0s;
  transition: -webkit-transform 0.25s cubic-bezier(0, 0, 0.25, 1) 0s;
  transition: transform 0.25s cubic-bezier(0, 0, 0.25, 1) 0s;
  transition: transform 0.25s cubic-bezier(0, 0, 0.25, 1) 0s, -webkit-transform 0.25s cubic-bezier(0, 0, 0.25, 1) 0s;
}
.lg-outer .lg-thumb-outer.lg-grab .lg-thumb-item {
  cursor: -webkit-grab;
  cursor: -o-grab;
  cursor: -ms-grab;
  cursor: grab;
}
.lg-outer .lg-thumb-outer.lg-grabbing .lg-thumb-item {
  cursor: move;
  cursor: -webkit-grabbing;
  cursor: -o-grabbing;
  cursor: -ms-grabbing;
  cursor: grabbing;
}
.lg-outer .lg-thumb-outer.lg-dragging .lg-thumb {
  -webkit-transition-duration: 0s !important;
  transition-duration: 0s !important;
}
.lg-outer.lg-thumb-open .lg-thumb-outer {
  -webkit-transform: translate3d(0, 0%, 0);
  transform: translate3d(0, 0%, 0);
}
.lg-outer .lg-thumb {
  padding: 10px 0;
  height: 100%;
  margin-bottom: -5px;
}
.lg-outer .lg-thumb-item {
  border-radius: 5px;
  cursor: pointer;
  float: left;
  overflow: hidden;
  height: 100%;
  border: 2px solid #fff;
  border-radius: 4px;
  margin-bottom: 5px;
}
@media (min-width: 1025px) {
  .lg-outer .lg-thumb-item {
    -webkit-transition: border-color 0.25s ease;
    transition: border-color 0.25s ease;
  }
}
.lg-outer .lg-thumb-item.active,
.lg-outer .lg-thumb-item:hover {
  border-color: #a90707;
}
.lg-outer .lg-thumb-item img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.lg-outer.lg-has-thumb .lg-item {
  padding-bottom: 120px;
}
.lg-outer.lg-can-toggle .lg-item {
  padding-bottom: 0;
}
.lg-outer.lg-pull-caption-up .lg-sub-html {
  -webkit-transition: bottom 0.25s ease;
  transition: bottom 0.25s ease;
}
.lg-outer.lg-pull-caption-up.lg-thumb-open .lg-sub-html {
  bottom: 100px;
}
.lg-outer .lg-toggle-thumb {
  background-color: #0d0a0a;
  border-radius: 2px 2px 0 0;
  color: #999;
  cursor: pointer;
  font-size: 24px;
  height: 39px;
  line-height: 27px;
  padding: 5px 0;
  position: absolute;
  right: 20px;
  text-align: center;
  top: -39px;
  width: 50px;
}
.lg-outer .lg-toggle-thumb:after {
  content: "\e1ff";
}
.lg-outer .lg-toggle-thumb:hover {
  color: #fff;
}
.lg-outer .lg-video-cont {
  display: inline-block;
  vertical-align: middle;
  max-width: 1140px;
  max-height: 100%;
  width: 100%;
  padding: 0 5px;
}
.lg-outer .lg-video {
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  overflow: hidden;
  position: relative;
}
.lg-outer .lg-video .lg-object {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
}
.lg-outer .lg-video .lg-video-play {
  width: 84px;
  height: 59px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -42px;
  margin-top: -30px;
  z-index: 1080;
  cursor: pointer;
}
.lg-outer .lg-has-vimeo .lg-video-play {
  /* background: url("../img/vimeo-play.png") no-repeat scroll 0 0 transparent; */
}
.lg-outer .lg-has-vimeo:hover .lg-video-play {
  /* background: url("../img/vimeo-play.png") no-repeat scroll 0 -58px transparent; */
}
.lg-outer .lg-has-html5 .lg-video-play {
  /* background: transparent url("../img/video-play.png") no-repeat scroll 0 0; */
  height: 64px;
  margin-left: -32px;
  margin-top: -32px;
  width: 64px;
  opacity: 0.8;
}
.lg-outer .lg-has-html5:hover .lg-video-play {
  opacity: 1;
}
.lg-outer .lg-has-youtube .lg-video-play {
  /* background: url("../img/youtube-play.png") no-repeat scroll 0 0 transparent; */
}
.lg-outer .lg-has-youtube:hover .lg-video-play {
  /* background: url("../img/youtube-play.png") no-repeat scroll 0 -60px transparent; */
}
.lg-outer .lg-video-object {
  width: 100% !important;
  height: 100% !important;
  position: absolute;
  top: 0;
  left: 0;
}
.lg-outer .lg-has-video .lg-video-object {
  visibility: hidden;
}
.lg-outer .lg-has-video.lg-video-playing .lg-object,
.lg-outer .lg-has-video.lg-video-playing .lg-video-play {
  display: none;
}
.lg-outer .lg-has-video.lg-video-playing .lg-video-object {
  visibility: visible;
}
.lg-progress-bar {
  background-color: #333;
  height: 5px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1083;
  opacity: 0;
  -webkit-transition: opacity 0.08s ease 0s;
  transition: opacity 0.08s ease 0s;
}
.lg-progress-bar .lg-progress {
  background-color: #a90707;
  height: 5px;
  width: 0;
}
.lg-progress-bar.lg-start .lg-progress {
  width: 100%;
}
.lg-show-autoplay .lg-progress-bar {
  opacity: 1;
}
.lg-autoplay-button:after {
  content: "\e01d";
}
.lg-show-autoplay .lg-autoplay-button:after {
  content: "\e01a";
}
.lg-outer.lg-css3.lg-zoom-dragging
  .lg-item.lg-complete.lg-zoomable
  .lg-img-wrap,
.lg-outer.lg-css3.lg-zoom-dragging .lg-item.lg-complete.lg-zoomable .lg-image {
  -webkit-transition-duration: 0s;
  transition-duration: 0s;
}
.lg-outer.lg-use-transition-for-zoom
  .lg-item.lg-complete.lg-zoomable
  .lg-img-wrap {
  -webkit-transition: -webkit-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
  transition: -webkit-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
  transition: transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
  transition: transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, -webkit-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
}
.lg-outer.lg-use-left-for-zoom .lg-item.lg-complete.lg-zoomable .lg-img-wrap {
  -webkit-transition: left 0.3s cubic-bezier(0, 0, 0.25, 1) 0s,
    top 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
  transition: left 0.3s cubic-bezier(0, 0, 0.25, 1) 0s,
    top 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
}
.lg-outer .lg-item.lg-complete.lg-zoomable .lg-img-wrap {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
.lg-outer .lg-item.lg-complete.lg-zoomable .lg-image {
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  -webkit-transition: -webkit-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s,
    opacity 0.15s !important;
  -webkit-transition: opacity 0.15s, -webkit-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s !important;
  transition: opacity 0.15s, -webkit-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s !important;
  transition: transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.15s !important;
  transition: transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.15s, -webkit-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s !important;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
#lg-zoom-in:after {
  content: "\e311";
}
#lg-actual-size {
  font-size: 20px;
}
#lg-actual-size:after {
  content: "\e033";
}
#lg-zoom-out {
  opacity: 0.5;
  pointer-events: none;
}
#lg-zoom-out:after {
  content: "\e312";
}
.lg-zoomed #lg-zoom-out {
  opacity: 1;
  pointer-events: auto;
}
.lg-outer .lg-pager-outer {
  bottom: 60px;
  left: 0;
  position: absolute;
  right: 0;
  text-align: center;
  z-index: 1080;
  height: 10px;
}
.lg-outer .lg-pager-outer.lg-pager-hover .lg-pager-cont {
  overflow: visible;
}
.lg-outer .lg-pager-cont {
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
  position: relative;
  vertical-align: top;
  margin: 0 5px;
}
.lg-outer .lg-pager-cont:hover .lg-pager-thumb-cont {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.lg-outer .lg-pager-cont.lg-pager-active .lg-pager {
  -webkit-box-shadow: 0 0 0 2px white inset;
          box-shadow: 0 0 0 2px white inset;
}
.lg-outer .lg-pager-thumb-cont {
  background-color: #fff;
  color: #fff;
  bottom: 100%;
  height: 83px;
  left: 0;
  margin-bottom: 20px;
  margin-left: -60px;
  opacity: 0;
  padding: 5px;
  position: absolute;
  width: 120px;
  border-radius: 3px;
  -webkit-transition: opacity 0.15s ease 0s, -webkit-transform 0.15s ease 0s;
  transition: opacity 0.15s ease 0s, -webkit-transform 0.15s ease 0s;
  transition: opacity 0.15s ease 0s, transform 0.15s ease 0s;
  transition: opacity 0.15s ease 0s, transform 0.15s ease 0s, -webkit-transform 0.15s ease 0s;
  -webkit-transform: translate3d(0, 5px, 0);
  transform: translate3d(0, 5px, 0);
}
.lg-outer .lg-pager-thumb-cont img {
  width: 100%;
  height: 100%;
}
.lg-outer .lg-pager {
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  -webkit-box-shadow: 0 0 0 8px rgba(255, 255, 255, 0.7) inset;
          box-shadow: 0 0 0 8px rgba(255, 255, 255, 0.7) inset;
  display: block;
  height: 12px;
  -webkit-transition: box-shadow 0.3s ease 0s;
  -webkit-transition: -webkit-box-shadow 0.3s ease 0s;
  transition: -webkit-box-shadow 0.3s ease 0s;
  transition: box-shadow 0.3s ease 0s;
  transition: box-shadow 0.3s ease 0s, -webkit-box-shadow 0.3s ease 0s;
  width: 12px;
}
.lg-outer .lg-pager:hover,
.lg-outer .lg-pager:focus {
  -webkit-box-shadow: 0 0 0 8px white inset;
          box-shadow: 0 0 0 8px white inset;
}
.lg-outer .lg-caret {
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px dashed;
  bottom: -10px;
  display: inline-block;
  height: 0;
  left: 50%;
  margin-left: -5px;
  position: absolute;
  vertical-align: middle;
  width: 0;
}
.lg-fullscreen:after {
  content: "\e20c";
}
.lg-fullscreen-on .lg-fullscreen:after {
  content: "\e20d";
}
.lg-outer #lg-dropdown-overlay {
  background-color: rgba(0, 0, 0, 0.25);
  bottom: 0;
  cursor: default;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1081;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: visibility 0s linear 0.18s, opacity 0.18s linear 0s;
  transition: visibility 0s linear 0.18s, opacity 0.18s linear 0s;
}
.lg-outer.lg-dropdown-active .lg-dropdown,
.lg-outer.lg-dropdown-active #lg-dropdown-overlay {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
  -webkit-transform: translate3d(0, 0px, 0);
  transform: translate3d(0, 0px, 0);
  opacity: 1;
  visibility: visible;
}
.lg-outer.lg-dropdown-active #lg-share {
  color: #fff;
}
.lg-outer .lg-dropdown {
  background-color: #fff;
  border-radius: 2px;
  font-size: 14px;
  list-style-type: none;
  margin: 0;
  padding: 10px 0;
  position: absolute;
  right: 0;
  text-align: left;
  top: 50px;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translate3d(0, 5px, 0);
  transform: translate3d(0, 5px, 0);
  -webkit-transition: -webkit-transform 0.18s linear 0s,
    visibility 0s linear 0.5s, opacity 0.18s linear 0s;
  -webkit-transition: visibility 0s linear 0.5s,
    opacity 0.18s linear 0s, -webkit-transform 0.18s linear 0s;
  transition: visibility 0s linear 0.5s,
    opacity 0.18s linear 0s, -webkit-transform 0.18s linear 0s;
  transition: transform 0.18s linear 0s, visibility 0s linear 0.5s,
    opacity 0.18s linear 0s;
  transition: transform 0.18s linear 0s, visibility 0s linear 0.5s,
    opacity 0.18s linear 0s, -webkit-transform 0.18s linear 0s;
}
.lg-outer .lg-dropdown:after {
  content: "";
  display: block;
  height: 0;
  width: 0;
  position: absolute;
  border: 8px solid transparent;
  border-bottom-color: #fff;
  right: 16px;
  top: -16px;
}
.lg-outer .lg-dropdown > li:last-child {
  margin-bottom: 0px;
}
.lg-outer .lg-dropdown > li:hover a,
.lg-outer .lg-dropdown > li:hover .lg-icon {
  color: #333;
}
.lg-outer .lg-dropdown a {
  color: #333;
  display: block;
  white-space: pre;
  padding: 4px 12px;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 12px;
}
.lg-outer .lg-dropdown a:hover {
  background-color: rgba(0, 0, 0, 0.07);
}
.lg-outer .lg-dropdown .lg-dropdown-text {
  display: inline-block;
  line-height: 1;
  margin-top: -3px;
  vertical-align: middle;
}
.lg-outer .lg-dropdown .lg-icon {
  color: #333;
  display: inline-block;
  float: none;
  font-size: 20px;
  height: auto;
  line-height: 1;
  margin-right: 8px;
  padding: 0;
  vertical-align: middle;
  width: auto;
}
.lg-outer #lg-share {
  position: relative;
}
.lg-outer #lg-share:after {
  content: "\e80d";
}
.lg-outer #lg-share-facebook .lg-icon {
  color: #3b5998;
}
.lg-outer #lg-share-facebook .lg-icon:after {
  content: "\e901";
}
.lg-outer #lg-share-twitter .lg-icon {
  color: #00aced;
}
.lg-outer #lg-share-twitter .lg-icon:after {
  content: "\e904";
}
.lg-outer #lg-share-googleplus .lg-icon {
  color: #dd4b39;
}
.lg-outer #lg-share-googleplus .lg-icon:after {
  content: "\e902";
}
.lg-outer #lg-share-pinterest .lg-icon {
  color: #cb2027;
}
.lg-outer #lg-share-pinterest .lg-icon:after {
  content: "\e903";
}
.group {
  *zoom: 1;
}
.group:before,
.group:after {
  display: table;
  content: "";
  line-height: 0;
}
.group:after {
  clear: both;
}
.lg-outer {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  opacity: 0;
  -webkit-transition: opacity 0.15s ease 0s;
  transition: opacity 0.15s ease 0s;
}
.lg-outer * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.lg-outer.lg-visible {
  opacity: 1;
}
.lg-outer.lg-css3 .lg-item.lg-prev-slide,
.lg-outer.lg-css3 .lg-item.lg-next-slide,
.lg-outer.lg-css3 .lg-item.lg-current {
  -webkit-transition-duration: inherit !important;
  transition-duration: inherit !important;
  -webkit-transition-timing-function: inherit !important;
  transition-timing-function: inherit !important;
}
.lg-outer.lg-css3.lg-dragging .lg-item.lg-prev-slide,
.lg-outer.lg-css3.lg-dragging .lg-item.lg-next-slide,
.lg-outer.lg-css3.lg-dragging .lg-item.lg-current {
  -webkit-transition-duration: 0s !important;
  transition-duration: 0s !important;
  opacity: 1;
}
.lg-outer.lg-grab img.lg-object {
  cursor: -webkit-grab;
  cursor: -o-grab;
  cursor: -ms-grab;
  cursor: grab;
}
.lg-outer.lg-grabbing img.lg-object {
  cursor: move;
  cursor: -webkit-grabbing;
  cursor: -o-grabbing;
  cursor: -ms-grabbing;
  cursor: grabbing;
}
.lg-outer .lg {
  height: 100%;
  width: 100%;
  position: relative;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  max-height: 90%;
  top: 5%;
}
.lg-outer .lg-inner {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  white-space: nowrap;
}
.lg-outer .lg-item {
  /* background: url("../img/loading.gif") no-repeat scroll center center transparent; */
  display: none !important;
}
.lg-outer.lg-css3 .lg-prev-slide,
.lg-outer.lg-css3 .lg-current,
.lg-outer.lg-css3 .lg-next-slide {
  display: inline-block !important;
}
.lg-outer.lg-css .lg-current {
  display: inline-block !important;
}
.lg-outer .lg-item,
.lg-outer .lg-img-wrap {
  display: inline-block;
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
}
.lg-outer .lg-item:before,
.lg-outer .lg-img-wrap:before {
  content: "";
  display: inline-block;
  height: 50%;
  width: 1px;
  margin-right: -1px;
}
.lg-outer .lg-img-wrap {
  position: absolute;
  padding: 0 5px;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.lg-outer .lg-item.lg-complete {
  background-image: none;
}
.lg-outer .lg-item.lg-current {
  z-index: 1060;
}
.lg-outer .lg-image {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  max-height: 100%;
  width: auto !important;
  height: auto !important;
}
.lg-outer.lg-show-after-load .lg-item .lg-object,
.lg-outer.lg-show-after-load .lg-item .lg-video-play {
  opacity: 0;
  -webkit-transition: opacity 0.15s ease 0s;
  transition: opacity 0.15s ease 0s;
}
.lg-outer.lg-show-after-load .lg-item.lg-complete .lg-object,
.lg-outer.lg-show-after-load .lg-item.lg-complete .lg-video-play {
  opacity: 1;
}
.lg-outer .lg-empty-html {
  display: none;
}
.lg-outer.lg-hide-download #lg-download {
  display: none;
}
.lg-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1040;
  /* background-color: #000; */
  background-color: rgba(0, 0, 0, 0.75);
  opacity: 0;
  -webkit-transition: opacity 0.15s ease 0s;
  transition: opacity 0.15s ease 0s;
}
.lg-backdrop.in {
  opacity: 1;
}
.lg-css3.lg-no-trans .lg-prev-slide,
.lg-css3.lg-no-trans .lg-next-slide,
.lg-css3.lg-no-trans .lg-current {
  -webkit-transition: none 0s ease 0s !important;
  transition: none 0s ease 0s !important;
}
.lg-css3.lg-use-css3 .lg-item {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
.lg-css3.lg-use-left .lg-item {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
.lg-css3.lg-fade .lg-item {
  opacity: 0;
}
.lg-css3.lg-fade .lg-item.lg-current {
  opacity: 1;
}
.lg-css3.lg-fade .lg-item.lg-prev-slide,
.lg-css3.lg-fade .lg-item.lg-next-slide,
.lg-css3.lg-fade .lg-item.lg-current {
  -webkit-transition: opacity 0.1s ease 0s;
  transition: opacity 0.1s ease 0s;
}
.lg-css3.lg-slide.lg-use-css3 .lg-item {
  opacity: 0;
}
.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-prev-slide {
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0);
}
.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-next-slide {
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
}
.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-current {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  opacity: 1;
}
.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-prev-slide,
.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-next-slide,
.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-current {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s,
    opacity 0.1s ease 0s;
  -webkit-transition: opacity 0.1s ease 0s, -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s;
  transition: opacity 0.1s ease 0s, -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s, -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s;
}
.lg-css3.lg-slide.lg-use-left .lg-item {
  opacity: 0;
  position: absolute;
  left: 0;
}
.lg-css3.lg-slide.lg-use-left .lg-item.lg-prev-slide {
  left: -100%;
}
.lg-css3.lg-slide.lg-use-left .lg-item.lg-next-slide {
  left: 100%;
}
.lg-css3.lg-slide.lg-use-left .lg-item.lg-current {
  left: 0;
  opacity: 1;
}
.lg-css3.lg-slide.lg-use-left .lg-item.lg-prev-slide,
.lg-css3.lg-slide.lg-use-left .lg-item.lg-next-slide,
.lg-css3.lg-slide.lg-use-left .lg-item.lg-current {
  -webkit-transition: left 1s cubic-bezier(0, 0, 0.25, 1) 0s,
    opacity 0.1s ease 0s;
  -webkit-transition: left 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  transition: left 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
}
@media only screen and (min-height: 690px) {
  .lg-actions .lg-next {
    top: auto;
    right: 50%;
    bottom: 20px;
    margin-right: -70px;
    opacity: 0.5;
  }

  .lg-actions .lg-prev {
    top: auto;
    left: 50%;
    bottom: 20px;
    margin-left: -70px;
    opacity: 0.5;
  }

  .lg-actions .lg-next:hover,
  .lg-actions .lg-prev:hover {
    opacity: 1;
  }

  .lg-toolbar .lg-close {
    margin: 10px auto;
    float: none;
  }
}
@media only screen and (min-height: 900px) {
  .lg-toolbar .lg-close {
    margin-top: 80px;
  }
}
/* stylelint-enable */
/* Slider */
/* stylelint-disable */
.slick-slider {
  position: relative;

  display: block;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}
.slick-list {
  position: relative;

  display: block;
  overflow: hidden;

  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}
.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.slick-track {
  position: relative;
  top: 0;
  left: 0;

  display: block;
  margin-left: auto;
  margin-right: auto;
}
.slick-track:before,
.slick-track:after {
  display: table;

  content: "";
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}
.slick-slide {
  display: none;
  float: left;

  height: 100%;
  min-height: 1px;
}
[dir="rtl"] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;

  height: auto;

  border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
  display: none;
}
/* stylelint-enable */
/*
    GitHub URL: https://github.com/gucastiliao/video-popup-js
*/
/* stylelint-disable */
[dir="ltr"] .videopopupjs {
  text-align: left !important;
}
[dir="rtl"] .videopopupjs {
  text-align: right !important;
}
.videopopupjs {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
}
.videopopupjs--hide {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: videoPopupJsHide;
  animation-name: videoPopupJsHide;
}
.videopopupjs__close {
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: block;
  margin-bottom: 10px;
  margin-left: auto;
  color: rgba(255, 255, 255, 1);
}
.videopopupjs__close:after {
  width: 30px;
  height: 30px;
  display: block;
  text-align: center;
  content: "X";
  font-family: "Verdana";
  border-radius: 50%;
  background: #333;
  line-height: 2.2;
  font-size: 13px;
}
.videopopupjs__close:hover {
  opacity: 0.5;
}
.videopopupjs--animation {
  opacity: 0;
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: videoPopupJs;
  animation-name: videoPopupJs;
}
.videopopupjs__content {
  margin: 0 auto;
  height: 100%;
  height: 500px;
  width: 100%;
  margin-top: 5%;
}
.videopopupjs__content iframe {
  width: 100%;
  height: 100%;
}
.videopopupjs__block--notfound {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto 0;
  width: 100%;
  height: 500px;
  background-color: #fff;
  text-align: center;
  vertical-align: middle;
  line-height: 500px;
  font-family: "Arial";
  font-size: 20px;
}
@media (max-width: 768px) {
  .videopopupjs__content {
    max-width: 90% !important;
  }
}
@-webkit-keyframes videoPopupJs {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
@keyframes videoPopupJs {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
@-webkit-keyframes videoPopupJsHide {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
@keyframes videoPopupJsHide {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
/* stylelint-enable */
/* .page-404 {
  .l-page {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background: url('../../img/backgrounds/404.jpg') #1e2844 center/cover no-repeat;
  }

  .l-header {
    flex: 0 0 auto;
    border-color: transparent;
  }
  main {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    justify-content: flex-end;
    padding-bottom: 18.7037vh;

    .l-container {
      text-align: center;

      > p {
        font-family: var(--font-family-1);
        font-size: 1.5rem;
        line-height: 1.4;
        color: var(--white);
      }

      > .button { margin-top: 2.125rem; }
    }
  }

  @media (--viewport-small) {
    main > .l-container {
      > p { font-size: calc(1.5rem + (32 - 24) * ((100vw - 48rem) / (1920 - 768))); }
      > .button { margin-top: calc(0.875rem + (50 - 34) * ((100vw - 48rem) / (1920 - 768))); }
    }
  }

  @media (--viewport-large) {
    main > .l-container {
      > p { font-size: 2rem; }
      > .button { margin-top: 3.125rem; }
    }
  }
} */
.overlay-enter-active, .overlay-leave-active { -webkit-transition: all .5s ease-in-out; transition: all .5s ease-in-out;}
.overlay-enter, .overlay-leave-to { height: 0; }
.mobile-nav-enter .mobile-nav-link,
  .mobile-nav-enter .mobile-archive-nav > .label,
  .mobile-nav-enter .mobile-archive-nav-option { opacity: 0; }
.mobile-nav-enter-active .mobile-nav-link,
  .mobile-nav-enter-active .mobile-archive-nav > .label,
  .mobile-nav-enter-active .mobile-archive-nav-option { -webkit-transition: opacity .3s ease-in-out; transition: opacity .3s ease-in-out; }
.mobile-nav-enter-active .mobile-nav-list > li:nth-child(2) > .mobile-nav-link { -webkit-transition-delay: .1s; transition-delay: .1s; }
.mobile-nav-enter-active .mobile-nav-list > li:nth-child(3) > .mobile-nav-link { -webkit-transition-delay: .2s; transition-delay: .2s; }
.mobile-nav-enter-active .mobile-nav-list > li:nth-child(4) > .mobile-nav-link { -webkit-transition-delay: .3s; transition-delay: .3s; }
.mobile-nav-enter-active .mobile-nav-list > li:nth-child(5) > .mobile-nav-link { -webkit-transition-delay: .4s; transition-delay: .4s; }
.mobile-nav-enter-active .mobile-archive-nav > .label { -webkit-transition-delay: .5s; transition-delay: .5s; }
.mobile-nav-enter-active .mobile-archive-nav > .list > li > .mobile-archive-nav-option { -webkit-transition-delay: .6s; transition-delay: .6s; }
.mobile-nav-enter-active .mobile-archive-nav > .list > li:nth-child(2) > .mobile-archive-nav-option { -webkit-transition-delay: .7s; transition-delay: .7s; }
.mobile-nav-enter-active .mobile-archive-nav > .list > li:nth-child(3) > .mobile-archive-nav-option { -webkit-transition-delay: .8s; transition-delay: .8s; }
.search-leave-active,
.mobile-nav-leave-active,
.tabs-leave-active {
  -webkit-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
}
.search-enter-active .search-form {
  -webkit-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
}
.search-enter-active .search-form > * { -webkit-transition: all .2s ease-in-out .3s; transition: all .2s ease-in-out .3s; }
.search-enter .search-form {
  width: 0;
}
.search-enter .search-form > * { opacity: 0;}
.search-leave-to,
.tabs-leave-to { opacity: 0; }
.mobile-nav-leave-to,
.search-leave-to {
  opacity: 0;
}
.tabs-enter > .line { width: 0; }
.tabs-enter .tabs .tab { opacity: 0; }
.tabs-enter-active > .line { -webkit-transition: all .3s ease-in-out; transition: all .3s ease-in-out; }
.tabs-enter-active > .header > .title { -webkit-transition: all .2s ease-in-out .2s; transition: all .2s ease-in-out .2s;}
.tabs-enter-active .tabs .tab {
      -webkit-transition: all .2s ease-in-out;
      transition: all .2s ease-in-out
    }
.tabs-enter-active .tabs .tab:first-child {-webkit-transition-delay: .3s;transition-delay: .3s;}
.tabs-enter-active .tabs .tab:nth-child(2) {-webkit-transition-delay: .4s;transition-delay: .4s;}
.tabs-enter-active .tabs .tab:nth-child(3) {-webkit-transition-delay: .5s;transition-delay: .5s;}
/* ==========================================================================
   Helper classes
   ========================================================================== */
/*
 * Hide visually and from screen readers:
 * http://juicystudio.com/article/screen-readers-display-none.php
 */
/*
 * Hide only visually, but have it available for screen readers:
 * http://snook.ca/archives/html_and_css/hiding-content-for-accessibility
 */
._visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px
}
._unpadding-top { padding-top: 0 !important; }
._text-center { text-align: center !important; }
._flex-justify-center { -webkit-box-pack: center !important; -ms-flex-pack: center !important; justify-content: center !important; }
._clipped { overflow: hidden !important; }
._cover {
  background-size: cover;
  background-position: center;
}
/*._md-block {
  display: none !important;

  @include md {
    display: block !important;
  }
}*/
/* ._bg-secondary { background-color: var(--secondary); }
._bg-very-light-pink { background-color: var(--very-light-pink); }
._bg-light-blue { background: var(--light-blue); }
._bg-home-hero { background: url('../../img/backgrounds/home-hero.jpg') center/cover; } */
/* ._bg-blockquote { background: url('../../img/backgrounds/blockquote.jpg') #7896ab center/cover; }
._bg-blockquote-2 { background: url('../../img/backgrounds/blockquote-2.jpg') #7896ab center/cover; } */
/* ._bg-charles-colson-blue { background: url('../../img/backgrounds/charles-colson-blue.jpg') #7896ab center/cover; }
._bg-about { background: url('../../img/backgrounds/about.jpg') #7896ab center/cover; }
._bg-our-history { background: url('../../img/backgrounds/our-history.jpg') #7896ab center/cover; }
._bg-our-leadership { background: url('../../img/backgrounds/our-leadership.jpg') #7896ab center/cover; }

._text-white { color: var(--white); }
._text-primary { color: var(--primary); }
._text-secondary { color: var(--secondary); }
._text-light-blue { color: var(--light-blue); }
._theme-primary {
  background-color: var(--primary);

  h1,
  p, {
    color: #ededed;
  }
} */
/* ==========================================================================
   Print styles.
   Inlined to avoid the additional HTTP request:
   http://www.phpied.com/delay-loading-your-print-css/
   ========================================================================== */
@media print {
  *,
  *:before,
  *:after {
    background: transparent !important;
    color: #000 !important; /* Black prints faster:
                   http://www.sanbeiji.com/archives/953 */
    -webkit-box-shadow: none !important;
            box-shadow: none !important;
    text-shadow: none !important;
  }

  a,
  a:visited {
    text-decoration: underline;
  }

  a[href]:after {
    content: " (" attr(href) ")";
  }

  /*
   * Don't show links that are fragment identifiers,
   * or use the `javascript:` pseudo protocol
   */

  a[href^="#"]:after,
  a[href^="javascript:"]:after {
    content: "";
  }

  
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid;
  }

  /*
   * Printing Tables:
   * http://css-discuss.incutio.com/wiki/Printing_Tables
   */

  tr,
  img {
    page-break-inside: avoid;
  }

  img {
    max-width: 100% !important;
  }

  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }

  h2,
  h3 {
    page-break-after: avoid;
  }
}

